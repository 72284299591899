.auto-mode-parent-wrapper {
  margin: 0;
  height: 100vh;

  .room-table-box {
    height: calc(100vh - 68px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

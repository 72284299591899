.custom-scenario-popup-parent-wrapper {
  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40.5%;
    height: 45%;
    // height: auto;
    min-height: 520px;
  }

  .MuiPaper-root {
    background: var(--midnightNavy) !important;
    margin: 0 !important;
    border-radius: 20px;
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .popup-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }

  .popup-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--shadowNavy);
    padding: 20px;
    // border-top-left-radius: 20px;
    // border-top-right-radius: 20px;

    .popup-title {
      margin: 0;
      padding: 0;
      flex: 1;
      max-width: 170px;
    }

    .search-input-field {
      .MuiOutlinedInput-root {
        padding: 0;
        fieldset {
          border: none;
        }
      }
    }

    .cancel-icon {
      cursor: pointer;
      transition: var(--zoomInOutTransition);

      &:hover {
        transform: scale(1.2);
      }

      &:hover path {
        fill: var(--Crimson);
      }
    }
  }

  .ability-btns-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
  }

  .ability-btns-box {
    display: flex;
    gap: 13px;
    align-items: center;
    flex-wrap: wrap;
  }

  .ability-btn {
    height: 23px;
    //   width: 90px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }

  .ability-total-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }

  .ability-count {
    background-color: var(--shadowNavy);
    width: 59px;
    height: 32px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ability-text {
      font-size: 12px;
      font-weight: 600;
      color: var(--silverGray);
      text-align: center;
    }

    button {
      min-width: 15px;
      height: 100%;
    }
  }

  .popup-middle {
    padding: 0px {
      bottom: 16px;
    }

    .ability-box {
      display: flex;
      gap: 2.92%;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: var(--deepSlate);
      }
    }

    .ability-data {
      display: flex;
      gap: 15px;
      align-items: center;

      img {
        width: 54px;
        height: 79px;
        border-radius: 15px;
      }
    }
  }

  .popup-bottom {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    padding: 20px !important {
      top: 5px !important;
    }
  }

  .ability-criteria {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-left: 0px !important;
  }

  .action-btn-box {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }

  .action-btn {
    height: 48px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    margin: 0 !important;
    max-width: 140px;
    width: 18%;
    min-width: 100px;
    white-space: nowrap;
  }

  @include sm {
    .MuiDialog-container {
      width: 95% !important;
      // height: auto !important;
    }

    .popup-head {
      padding: 10px;
    }

    .ability-btns-wrap {
      padding: 16px 10px;
    }

    .popup-middle {
      .ability-box {
        display: flex;
        padding: 10px;
      }
    }

    .popup-bottom {
      padding: 10px !important  {
        top: 5px !important;
      }
    }

    .ability-criteria {
      flex-direction: column;
    }

    .action-btn {
      height: 45px;
      width: 112px;
      font-size: 17px;
    }
  }
}

.custom-scenario-video-popup-box {
  z-index: 3000;

  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40.5%;
    height: 45%;

    @include sm {
      width: 90% !important;
      height: auto !important;
    }
  }

  .MuiPaper-root {
    background: var(--shadowNavy) !important;
    margin: 0 !important;
    border-radius: 20px;
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

// for fasi styling
html[lang="fa"] {
  .custom-scenario-popup-parent-wrapper {
    .popup-head {
      direction: rtl;
      .search-input-field {
        direction: rtl;
      }
    }
  }
}

.room-footer-parent-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  padding: 0 15px;
  align-items: flex-end;
  box-sizing: border-box;

  .left-parent-wrapper {
    flex: 1;
    display: flex;
    gap: 25px;
    align-items: center;
  }

  .middle-parent-wrapper {
    flex: 2;
     display: flex;
     align-items: center;
     justify-content: center;
    .middle-icons {
      // width: 470px;
      height: 68px;
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;
      gap: 0px;
      overflow: hidden;
      position: relative;
    }
  }

  .like-icon-svg {
    path {
      fill: var(--darkGreen);
    }
  }

  .dislike-icon-svg {
    path {
      fill: var(--darkRed);
    }
  }

  .challenge-icon-svg {
    path {
      fill: var(--magentaPurple);
    }
  }

  .right-parent-wrapper {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
    flex: 1;
    align-items: flex-end;

    .footer-right-box {
      width: 186px;
      height: 100px;
      border-radius: 15px;

      &.box-visible {
        opacity: 1;
        transform: scale(1);
        transition:
          opacity 0.5s,
          transform 0.5s;
      }

      &.box-unvisible {
        opacity: 0;
        transform: scale(0);
        transition:
          opacity 0.5s,
          transform 0.5s;
      }

      .grid-screen-avatar {
        height: 50px;
        width: 50px;
      }
    }

    .ability-card-img-box {
      width: 100px;
      height: 100px;
      &.ability-card-img {
        visibility: hidden;
      }
    }
  }


 
  .footer-icon-wrapper {
    text-align: center;
    cursor: pointer;
    height: 57px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      transform: scale(1.05);
      transition: transform 0.3s;
    }
  }
  .mic-footer-icon-wrap{
    display: flex;
    height: 57px;
    .footer-icon-wrapper {
    width: 39px;
    }
  .rotate-icon {
      transition: transform 0.3s ease;
      transform: rotate(0deg);
  }
  .rotate-icon.rotated {
      transform: rotate(180deg);
  }
   }
  .video-audio-all-wrapper {
    width: 50px;
  }

  .footer-icon-box {
    position: relative;
    display: inline-flex;
  }
  .ability-icon-svg{
    svg{
      scale: 1.4;
      margin-top: 2px;
    }
  }

  .stop {
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 40px;
      border-radius: 10px;
      background-color: red;
      transform: rotate(-45deg) translateY(-50%);
      cursor: pointer;
    }
  }

  .stop-mic-all {
    &::after {
      top: 50%;
      left: -50%;
    }
  }

  .stop-video-all {
    &::after {
      top: 45%;
      left: -15%;
    }
  }

  .dula-users-sup {
    position: absolute;
    left: -10px;
    top: -10px;
  }

  // .callIcon {
  //   height: 40px;
  //   width: 40px;
  //   background-color: #ff0000;
  //   border-radius: 50%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  @include sm {
    bottom: 0;
    left: 0px;
    background-color: var(--black);
    padding: 0 4px;

    .left-parent-wrapper {
      display: none;
    }

    .middle-main-box {
      width: 100%;
      .middle-icons {
        width: 83vw;
        overflow-x: auto;
        margin: 0;
        height: 63px;
        gap: 6px;
        align-items: center;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }

    .footer-icon-wrapper {
      transform: scale(0.7);
      min-width: 47px;
      &:hover {
        transform: scale(0.8);
      }

      p {
        white-space: nowrap;
      }
    }

    .vertical-line-for-sm {
      background-color: var(--mutedGray);
      height: 47px;
      border: 1px solid var(--mutedGray);
      margin: 0 5px;
    }

    .leave-btn-for-sm {
      transform: scale(0.9);
      &:hover {
        transform: scale(0.8);
      }
    }
  }

  @include above-sm {
    .chat-icon-for-sm {
      display: none;
    }

    .invite-icon-for-sm {
      display: none;
    }

    .leave-btn-wrap-for-sm {
      display: none;
    }
  }
}

// TimeLeft.js scss
.room-time-left-wrapper {
  height: 52px;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

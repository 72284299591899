.room-table-parent-wrapper {
  width: 60%;
  padding-bottom: calc((1 / 2.3594) * 60%);
  background-color: #1D1F24;
  border: 2px solid var(--darkBlue);
  box-shadow: -3px 3px 3px var(--darkBlue);
  position: relative;
  border-radius: 100px;
  .players-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .top-players-wrapper {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: translateY(-50%);
  }

  .middle-players-wrapper {
    width: 100%;
    height: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    .middle-left-wrapper {
      position: absolute;
      top: 0%;
      left: 0%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      transform: translateX(calc(-19.7% / 2));
    }

    .middle-right-wrapper {
      position: absolute;
      top: 0%;
      left: 0%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;
      transform: translateX(calc(19.7% / 2));
    }

  }

  .bottom-players-wrapper {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: translateY(50%);
  }
}
.subscription-questions-wrapper {
  .accordion-wrapper {
    background-color: var(--coalBlack);
    max-width: 1150px;
    border: 1px solid var(--midNightSlate);
    border-radius: 8px;
    margin: auto {
      bottom: 8px !important;
      top: 0 !important;
    }
    .accordion-summary {
      height: 60px;
      padding: 16px 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--blueGray);
      text-align: left;
    }
    .accordion-details {
      padding: 0 20px 16px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--mediumBlueGray);
      padding-right: 48px;
    }
  }
}

// parent class has to given
html[lang="fa"] .subscription-questions-wrapper {
  .MuiAccordionSummary-content {
    justify-content: flex-end;
  }
  .accordion-details {
    text-align: right;
  }
}

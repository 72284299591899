.game-screen-parent-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100svh;
  width: 100%;

  .grid-main {
    padding: 0px 0px 13px 9px;
  }

  @include sm {
    .grid-main {
      padding: 6px 4px 0;
    }
  }
}

.chat-parent-wrapper {
  background-color: var(--transparentBlack);
  height: calc(100svh - 13px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(100%);
  transition: transform 0.5s;

  svg {
    cursor: pointer;
    transition: var(--zoomInOutTransition);

    &:hover {
      transform: scale(1.2);
    }
  }

  &.animate-chat {
    transform: translateX(0);
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 23px;
    border-bottom: 1px solid var(--white);
  }

  .cancel-icon {
    &:hover path {
      fill: var(--Crimson);
    }
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column;
    gap: 9px;
    padding: 0 10px;
    justify-content: flex-end;
    min-height: 100%;
    position: relative;
  }

  .emoji-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
    width: 100%;

    .EmojiPickerReact {
      border-radius: 0 !important;
    }
  }

  .left-messages-box {
    background-color: var(--slateGray);
    font-weight: 300;
    border-radius: 10px 10px 10px 0;
    max-width: 48%;
    display: inline-block;
  }

  .right-messages-box {
    background-color: var(--primary);
    font-weight: 300;
    border-radius: 10px;
    float: right;
    max-width: 48%;
    display: inline-block;
  }

  .chat-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px;

    .msg-send-icon {
      &:hover path {
        fill: var(--primary);
      }
    }

    .emoji-icon-btn {
      &:hover path {
        fill: var(--yellow);
      }
    }
  }

  @include sm {
    height: calc(100svh);
  }
}

html[lang="fa"] .chat-parent-wrapper .chat-input-field {
  .MuiInputBase-input {
    text-align: right;
  }
}

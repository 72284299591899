.add-ability-popup-parent-wrapper {
  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15.9%;
    height: 15.6%;
    min-width: 305px;
    min-height: 175px;
    // height: auto;
  }

  .MuiPaper-root {
    background: var(--midnightNavy) !important;
    margin: 0 !important;
    border-radius: 20px;
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .popup-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    padding: 15px;
  }

  .popup-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .popup-title {
      margin: 0;
      padding: 0;
      flex: 4;
      text-align: center;
    }

    .cancel-icon {
      cursor: pointer;
      transition: var(--zoomInOutTransition);

      &:hover {
        transform: scale(1.2);
      }

      &:hover path {
        fill: var(--Crimson);
      }
    }
  }

  .popup-middle {
    display: flex;
    justify-content: center;
    padding: 8px !important;

    .enter-ability-textfield {
      width: 186px;
      // height: 33px;

      .MuiOutlinedInput-root {
        fieldset {
          border-color: var(--mutedGray);
          border-radius: 5px;
        }

        :hover fieldset {
          border-color: var(--mutedGray);
        }

        &.Mui-focused fieldset {
          border-color: var(--primary);
        }
      }
    }
  }

  .popup-bottom {
    display: flex;
    justify-content: center;
    padding: 0px !important;
  }

  .action-btn {
    height: 29px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
    max-width: 140px;
    width: 18%;
    min-width: 90px;
  }

  @include sm {

    .popup-wrap {
      padding: 10px;
    }

    .action-btn {
      width: 112px;
    }
  }
}

html[lang="fa"] .add-ability-popup-parent-wrapper {
  .popup-middle {
    .enter-ability-textfield {
      input {
        direction: rtl;
      }

      .Mui-error {
        text-align: right;
      }
    }
  }
}
// PasswordInput.js component scss
.password-input-parent-wrap {
  .password-input-field {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--mutedGray);
      }

      :hover fieldset {
        border-color: var(--mutedGray);
      }

      &.Mui-focused fieldset {
        border-color: var(--primary);
      }
    }
  }
}

html[lang="fa"] .password-input-parent-wrap {
  label {
    right: 25px;
    transform-origin: 95%;
  }
  .MuiOutlinedInput-notchedOutline {
    text-align: right;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
}

// SubmitBtn.js component scss
.auth-confirmation-btn {
  background-color: var(--primary);
  border-radius: 5px;
  height: 54px;
  font-weight: 700;
  font-size: 16px;
  text-transform: none;
}

// UserNameInput.js component scss
.username-input-parent-wrap {
  .username-input-field {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--mutedGray);
      }

      :hover fieldset {
        border-color: var(--mutedGray);
      }

      &.Mui-focused fieldset {
        border-color: var(--primary);
      }
    }
  }
}

html[lang="fa"] .username-input-parent-wrap {
  label {
    right: 25px;
    transform-origin: 95%;
  }
  .MuiOutlinedInput-notchedOutline {
    text-align: right;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
}

// Terms.js component scss
html[lang="fa"] .terms-checkbox {
  margin-left: -4px;
  margin-right: -11px;
}

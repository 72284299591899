// InvitePopup.js game-room component scss
.invite-popup-parent-wrapper {
  min-width: 441px;
  height: 236px;
  background-color: var(--black);
  position: fixed;
  z-index: 1000;
  border-radius: 15px;
  bottom: 100px;
  transition: transform 500ms;
  left: 9px;
  padding: 25px;

  svg {
    cursor: pointer;
    transition: var(--zoomInOutTransition);

    &:hover {
      transform: scale(1.2);
    }
  }

  &.hide-invite-popup {
    transform: translateY(150%);
  }

  .invite-popup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancel-icon {
      &:hover path {
        fill: var(--Crimson);
      }
    }
  }

  .room-link-box {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 12px;
    height: 43px;
    background-color: var(--semiTransparentDarkGreen);
    border-radius: 5px;
  }

  .credententials-wrap {
    display: flex;
    gap: 46px;
    .credententials-box {
      display: inline-flex;
      height: 43px;
      gap: 20px;
      align-items: center;
      padding: 12px;
      background-color: var(--semiTransparentDarkGreen);
      border-radius: 5px;
    }
  }

  @include sm {
    min-width: calc(100% - 12px);
    bottom: 70px;
    left: 6px;
    right: 6px;

    &.hide-invite-popup {
      transform: translateY(130%);
    }
  }
}

html[lang="fa"] .invite-popup-parent-wrapper {
  direction: rtl;

  .invite-popup-headings {
    direction: ltr;
    text-align: right;
  }
}

// InfoPopup.js game-room component scss
.game-info-popup-parent {
  background: var(--semiTransparentBlack);
  position: fixed;
  z-index: 1000;
  height: 63px;
  width: auto;
  top: 16px;
  left: 25px;
  padding: 12px 18px;
  border-radius: 15px;
  transition: opacity 300ms;

  &.hide-info-popup {
    opacity: 0;
  }

  .info-popup-flex {
    display: flex;
    gap: 31px;
  }

  @include sm {
    top: 32px;
    left: 15px;
  }
}

// ReadyToPlayPopup.js game-room component scss (ability card)

.ability-card-parent-wrapper {
  position: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  max-width: 23.76svw;
  height: 66.91svh;
  border-radius: 25px;
  bottom: 60px;
  transform: scale(0);
  z-index: 2000;
  transition: all 1s ease;
  transform-origin: bottom center;

  &.animate {
    bottom: 25%;
    right: 40%;
    transform: scale(1);
  }

  .cancel-icon-wrap {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin: 25px 25px 0 0;
  }

  .cancel-icon {
    cursor: pointer;
    transition: var(--zoomInOutTransition);

    &:hover {
      transform: scale(1.2);
    }

    &:hover path {
      fill: var(--Crimson);
    }
  }

  .content-box {
    background-color: var(--translucentCharcoal);
    backdrop-filter: blur(5px);
    padding: 19px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .action-btn-wrap {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
  }

  @include sm {
    max-width: 100%;
    height: 482px;
    width: calc(100% - 24px);
    left: -12%;
    right: 12px;
    bottom: -23%;
    transform: translateY(0) scale(0);
    transition:
      bottom 1s,
      left 1s,
      transform 1s;

    &.animate {
      left: 12px;
      bottom: 50%;
      transform: translateY(50%) scale(1);
    }
  }
}

// youtube video popup, it's styles should be outside from ".ability-card-parent-wrapper"
.ability-video-popup-box {
  z-index: 3000;

  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40.5%;
    height: 45%;

    @include sm {
      width: 90% !important;
      height: auto !important;
    }
  }

  .MuiPaper-root {
    background: var(--shadowNavy) !important;
    margin: 0 !important;
    border-radius: 20px;
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

// for farsi styling
html[lang="fa"] .ability-card-parent-wrapper {
  text-align: right;
}

// ActionMenu.js game-room component scss
.action-box-parent-wrapper {
  display: none;

  .action-bar-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%);
  }

  .action-box {
    background-color: var(--halfTransparentBlack);
    height: 31px;
    border-radius: 15px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--semiTransparentBlack);
    }
  }
}

// N0Connection.js game-room popup styling

.noconnection-dialog-parent-wrapper {
  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60% !important;
    height: auto !important;

    @include sm {
      width: 90% !important;
      height: auto !important;
    }
  }

  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background-color: var(--black) !important;
    opacity: 0.3 !important;
  }

  .MuiPaper-root {
    background: none !important;
    box-shadow: none;
    padding: 10px;
    margin: 0;
  }

  .popup-title {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 10px;
    padding: 0;
  }

  .popup-middle {
    padding: 0;
    padding-left: 35px;
    width: 70%;
    @include sm {
      width: 100%;
    }
  }

  .popup-bottom {
    justify-content: flex-start;
    padding-left: 35px;
  }
}

.waiting-popup-parent-wrapper {
  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40.5%;
    height: 45%;
  }
  
  .MuiPaper-root {
    background: var(--shadowNavy) !important;
    margin: 0 !important;
    border-radius: 20px;
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .background-image {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 20px;
    transition: background-size 250ms;
    background-size: 100% 100%;
    opacity: 0.4;
  }

  .popup-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    z-index: 10;
  }

  .popup-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .popup-title {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-weight: 800;
      color: var(--silverGray);
    }

    .cancel-icon {
      cursor: pointer;
      transition: var(--zoomInOutTransition);
      &:hover {
        transform: scale(1.2);
      }
      &:hover path {
        fill: var(--Crimson);
      }
    }
  }

  .popup-middle {
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .user-icons-wrapper {
      display: flex;
      gap: 14px;
      justify-content: center;
      flex-wrap: wrap;
    }

    .user-colored-icons {
      & path {
        fill: var(--primary);
      }
    }

    .user-numbers {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      padding-top: 7px;
    }
  }

  .popup-bottom {
    position: absolute;
    left: 25px;
    bottom: 25px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 48px;

    & svg {
      cursor: pointer;
      transition: var(--zoomInOutTransition);
    }

    & svg:hover {
      transform: scale(1.2);
    }
  }

  .bottom-buttons {
    padding: 0 !important;
    display: flex;
    gap: 16px !important;
    justify-content: center !important;
    align-items: center;
  }

  .action-btn {
    height: 48px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    margin: 0 !important;
  }

  .action-link {
    margin: 0 !important;
    max-width: 140px;
    width: 18%;
    min-width: 90px;
  }

  @include sm {
    .MuiDialog-container {
      width: 90% !important;
      height: auto !important;
    }

    .popup-wrap {
      padding: 15px;
    }

    .popup-bottom {
      left: 0;
      bottom: 0;
      position: relative;
    }

    .action-btn {
      height: 45px;
      width: 112px;
      font-size: 17px;
    }

    .action-link {
      width: 112px;
    }
  }
}

.not-found-parent-wrapper {
  .permission-denied {
    background: var(--navyBlue);
    width: 100%;
    min-height: 100svh;
    height: 100%;
    margin: 0;
    position: relative;
    padding-bottom: 40px;

    .tsparticles {
      position: fixed;
      width: 100%;
      height: 100%;
      opacity: 0.23;
    }

    .denied-wrapper {
      max-width: 390px;
      width: 100%;
      display: block;
      margin: 0 auto;
      position: relative;
      padding-top: 8vh;

      .denied-heading {
        text-align: center;
        color: var(--white);
        font-family: "Dosis", sans-serif !important;
        font-size: 100px;
        margin-bottom: 0;
        font-weight: 800;
      }

      .denied-sub-heading {
        text-align: center;
        color: var(--white);
        font-size: 19px;
        line-height: 23px;
        max-width: 330px;
        margin: 0 auto 30px auto;
        font-family: "Dosis", sans-serif !important;
        font-weight: 400;
        span {
          position: relative;
          width: 65px;
          display: inline-block;
          &::after {
            content: "";
            border-bottom: 3px solid #ffbb39;
            position: absolute;
            left: 0;
            top: 43%;
            width: 100%;
          }
        }
      }

      .astronaut {
        width: 43px;
        position: absolute;
        right: 20px;
        animation: spin 4.5s infinite linear;
      }

      @keyframes spin {
        0% {
          transform: rotateZ(0deg);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }

      .denied-link-wrap {
        text-decoration: none;
        margin-bottom: 40px;
        .denied-link {
          background: none;
          color: var(--white);
          padding: 12px 0px 10px 0px;
          border: 1px solid var(--white);
          outline: none;
          border-radius: 7px;
          width: 150px;
          font-size: 15px;
          text-align: center;
          margin: 0 auto;
          //   vertical-align: middle;
          display: block;
          margin-top: 25px;
          font-family: "Dosis", sans-serif !important;
          font-weight: 400;
          &:hover {
            color: var(--goldenYellow);
            border-color: var(--goldenYellow);
            cursor: pointer;
            opacity: 1;
          }
        }
      }

      @include sm {
        padding-top: 4vh;

        .astronaut {
          width: 35px;
          right: 40px;
        }
      }
    }
  }
}

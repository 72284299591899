// ProfileDrawer.js scss
.profile-drawer-parent-wrap {
  .drawer-avatar-btn {
    height: 50px;
    width: 50px;
    min-width: 50px;
    padding: 0;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }

    @include sm {
      height: 45px;
      width: 45px;
    }
  }
}

// ProfileDrawer.js scss (profile drawer list)
.MuiDrawer-paperAnchorRight {
  .profile-drawer-list {
    color: white;
    width: 287px;
    height: 100svh;
    padding: 16px 9px;
    background-color: var(--shadowNavy);
    @include sm {
      width: 100svw;
    }

    .drawer-profile-id {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 11px;
      padding-right: 11px;

      .cancel-icon {
        cursor: pointer;
        transition: var(--zoomInOutTransition);
        &:hover {
          transform: scale(1.2);
        }
        &:hover path {
          fill: var(--Crimson);
        }
      }
    }

    .menu-item-box {
      height: 35px;
      border-radius: 5px;
      padding: 12px 14px;
      margin-bottom: 2px;
      gap: 8px;
      &:hover {
        background-color: var(--charcoalSlate);
      }
      .menu-item-icon {
        min-width: 11px;
      }

      .menu-item-text {
        color: var(--cloudWhite);
        margin: 0;
        span {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .items-divider-line {
      background-color: var(--charcoalSlate);
    }
    .span-moons {
      color: var(--meadowGreen);
      border: 1px solid var(--meadowGreen);
      border-radius: 5px;
      font-size: 8px;
      font-weight: 500;
      padding: 5px 9px;
    }
  }
}

// ProfileAvatar.js scss

// "extend/inheritance" enable us to share css properties using @extend in scss
%profile-avatar-props {
  width: 50px;
  height: 50px;
  border: 2.6px solid var(--white);
  img {
    border-radius: 50%;
  }
}
.profile-avatar-parent {
  .profile-avatar-badge {
    .MuiBadge-badge {
      position: absolute;
      width: 20px;
      height: 20px;
      padding: 3px;
      background-color: var(--primary);
      color: var(--white);
      bottom: 5%;
      right: 50%;
      z-index: 12;
      display: flex;
      align-items: center;
      align-content: flex-end;
    }
    .avatar-img-btn {
      @extend %profile-avatar-props;
    }

    .avatar-img {
      @extend %profile-avatar-props;
    }

    @include sm {
      .avatar-img-btn {
        width: 45px;
        height: 45px;
      }
    }
  }
}

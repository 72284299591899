.auth-pages-parent-wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 10px;

  .auth-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px {
      bottom: 0
    };
  }

  .form-wrap {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-box {
    display: flex;
    flex-direction: column;
    width: 343px;
    margin: auto;
  }

  .warning-alert {
    // height: 35px;
    width: 100%;
    background-color: var(--pinkishRed);
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: var(--Crimson);
    display: flex;
    // align-items: center;
    align-items: stretch;
    gap: 9px;
    padding: 7px;
  }

  .link-hover {
    &:hover {
      opacity: 0.9;
    }
  }
  // all above classes are applied to every auth page
  // following will used to overlay them

  // "&" is used as both .auth-pages-parent-wrapper and .forgot-password-parent-wrap are applied to same element
  &.forgot-password-parent-wrap {
    .form-box {
      width: auto;
    }
    .forgot-form {
      width: 343px;
    }
  }

  &.register-parent-wrap {
    max-height: fit-content;
  }

  &.otp-parent-wrap {
    .code-sent-box {
      height: 54px;
      width: 100px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary);
      position: absolute;
      left: 50%;
      top: 34px;
      transform: translateX(-50%);
      z-index: 1;
    }

    .form-box {
      width: 323px;
    }

    .otp-inputs-wrap {
      display: flex;
      justify-content: space-between;
      gap: 7px;
      .otp-input {
        height: 50px;
        width: 48px;

        input {
          color: var(--white);
          font-size: 30px;
          font-weight: 700;
          padding: 0;
          height: 50px;
          text-align: center;
        }
        
        fieldset {
          border-color: var(--mutedGray);
        }
        .Mui-focused fieldset {
          border-color: var(--primary);
        }
      }
    }

    .otp-resend-box {
      display: flex;
      gap: 6px;
      align-items: center;

      .otp-reload-img {
        height: 13px;
        width: 13px;
      }
    }
  }

  @include sm {
    .auth-header {
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
    }

    &.forgot-password-parent-wrap .form-box {
      width: 343px;
    }

    &.register-parent-wrap {
      max-height: 100vh;
    }

    &.otp-parent-wrap .code-sent-box {
      top: 25px;
    }
  }
}

// for farsi styling, whole auth pages as .auth-pages-parent-wrapper is given to each auth page
html[lang="fa"] .auth-pages-parent-wrapper {
  .form-box {
    direction: rtl;
  }
  .otp-inputs-wrap {
    direction: ltr;
  }
}

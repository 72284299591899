.game-lobby-parent-wrap {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100svh;
  width: 100svw;

  .game-container {
    height: 100%;
    box-sizing: border-box;
    padding: 2.3148svh 4.3519svh 6svh;
    display: flex;
    flex-direction: column;

    .game-body {
      flex: 1;
      display: flex;
      margin-top: 5.3333svh;
      flex-grow: 1;
      column-gap: 3.64583svw;
      overflow: hidden;
    }

    .body-left {
      flex: 2;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .body-grid {
      margin-top: 3.2407svh;
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .grid-wrapper {
      box-sizing: border-box;
      height: 100%;
      width: calc(100% + 4.1667svw);
      margin-left: -4.1667svw;
      flex: 1;
      overflow-y: scroll;
      padding-right: 5px;
      row-gap: 4.4444svh;

      .MuiGrid-item {
        padding-left: 4.1667svw;
      }

      // &::-webkit-scrollbar {
      //   width: 0.39em;
      //   @include sm {
      //     width: 0.3em;
      //   }
      // }
      // &::-webkit-scrollbar-thumb {
      //   background-color: var(--slateGray);
      //   border-radius: 10px;
      //   height: 10px;
      // }
    }

    .body-right {
      box-sizing: border-box;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      .right-info-wrapper {
        overflow-y: auto;
        //   &::-webkit-scrollbar {
        //     width: 0.39em;
        //     @include sm {
        //       width: 0.3em;
        //     }
        //   }
        //   &::-webkit-scrollbar-thumb {
        //     background-color: var(--slateGray);
        //     border-radius: 10px;
        //     height: 10px;
        //   }
      }
    }

    .scrollbar-visible::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    .body-tabs-wrapper {
      min-height: 40.3px;

      .MuiTabs-flexContainer {
        border-bottom: 2px solid var(--slateGray);
        gap: 23px;
      }

      button {
        font-size: 18px;
        padding: 8px 0;
        text-align: left;
        min-height: 29px;
        min-width: auto;
        color: var(--white);

        &.Mui-selected {
          color: var(--primary);
        }
      }
    }

    .body-no-info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }

    @include x-md {
      .game-body {
        flex-direction: column;
        row-gap: 10px;
        margin-top: 7px;
      }

      .body-left {
        height: 50%;
        flex: 1;
      }

      .body-right {
        height: 50%;
        padding-bottom: 15px;

        .MuiFormHelperText-root {
          margin-left: 0px;
        }
      }
    }

    @include sm {
      padding: 5%;

      .body-grid {
        margin-top: 13px;
      }

      .grid-wrapper {
        row-gap: 13px;
      }

      .body-tabs-wrapper {
        min-height: 35.5px;

        button {
          font-size: 14px;
        }
      }
    }
  }
}

html[lang="fa"] {
  .game-lobby-parent-wrap {
    .card-info-tab-wrap .MuiTabs-flexContainer {
      justify-content: flex-end;
    }
  }
}

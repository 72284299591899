.voting-component-parent-wrapper {
  margin-top: 3.2407svh;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  background-color: transparent;

  .table-head-row {
    background-color: transparent;

    .head-items {
      background-color: transparent;
      color: var(--lightSilverGray);
      font-size: 16px;
      font-weight: 600;
      padding-top: 0;
      padding-bottom: 11px;
      border: none;
    }
  }

  .table-body-row {
    .body-items {
      border: none;
      padding: 0;

      .cell-content {
        margin-bottom: 16px;
        background-color: var(--darkGray);
        //   padding: 14px 24px;
        padding: 0 24px;
        font-size: 18px;
        font-weight: 600;
        color: var(--white);
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(4n + 1) {
        .cell-content {
          width: 63px;
          text-align: center;
          border-radius: 10px;
          margin-right: 26px;
        }
      }

      &:nth-child(4n + 2) {
        .cell-content {
          justify-content: flex-start;
          border: {
            top-left-radius: 10px;
            bottom-left-radius: 10px;
          }
        }
      }

      &:nth-child(4n + 4) {
        .cell-content {
          border: {
            top-right-radius: 10px;
            bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  .vote-btn {
    color: var(--white);
    border-radius: 10px;
    text-transform: none;
    font-size: 10px;
    width: 83px;
    height: 34px;
  }
}

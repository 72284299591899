.rejoin-wrap {
    height: 150px;
    width: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(63, 148, 178, 0.6);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999999999;
    cursor: pointer;
    transform: translate(-50%, -50%);
    flex-direction: column;
    text-align: center;
    padding: 5px;
    transition: all 0.5s ease;

    &:hover {
        background-color: rgba(63, 148, 178, 1);

    }

    h3,
    p {
        color: var(--white);
    }

    h3 {
        font-size: 15px;
        margin-bottom: 6px;
    }

    p {
        font-size: 11px;
    }
}
.media-permission-popup-wrapper {
  .MuiDialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 41%;
    height: 45%;
    min-width: 779px;
    min-height: 484px;
  }

  .MuiPaper-root {
    overflow: hidden;
    background: var(--shadowNavy) !important;
    margin: 0 !important;
    border-radius: 20px;
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .background-video {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: background-size 250ms;
    object-fit: fill;
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transform: scaleX(-1);
    }
  }

  .popupWrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }
  .popupHead {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px;
    .cancelIcon {
      cursor: pointer;
      transition: var(--zoomInOutTransition);

      &:hover {
        transform: scale(1.2);
      }

      &:hover path {
        fill: var(--Crimson);
      }
    }
  }
  .popupMiddle {
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    .middleContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      width: calc(36.07% + 25px);
    }
  }
  .popupbottom {
    display: flex;
    gap: 16px !important;
    justify-content: space-between !important;
    align-items: center;
    padding: 0;
    .actionBtn {
      width: 140px;
      height: 48px;
      border-radius: 10px;
      font-size: 17px;
      font-weight: 600;
      margin: 0 !important;
      text-transform: none;
    }
    .iconWrapper {
      text-align: center;
      cursor: pointer;
      height: 47px;
      width: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        transform: scale(1.05);
        transition: transform 0.3s;
      }
      .iconBox {
        position: relative;
        display: inline-flex;
        align-items: center;
        min-height: 34px;
      }
      .stop {
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: 40px;
          border-radius: 10px;
          background-color: red;
          transform: rotate(-45deg) translateY(-50%);
          cursor: pointer;
          top: 50%;
          left: -50%;
        }
      }
    }
  }
  @include sm {
    .MuiDialog-container {
      width: 90% !important;
      height: auto !important;
    }

    .popupHead {
      padding: 15px;
    }

    .popupMiddle {
      padding: 15px;

      .middleContent {
        width: 80%;
      }
    }

    .popupbottom {
      padding: 15px;

      .actionBtn {
        height: 45px;
        width: 112px;
        font-size: 17px;
      }
    }
  }
}

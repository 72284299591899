.subscription-card-parent-wrapper {
  background-color: var(--coalBlack);
  border-radius: 25px;
  color: var(--blueGray);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  .card-head {
    padding: 24px 24px 30px;
    border: {
      top: 1px solid var(--darkMutedGray);
      right: 1px solid var(--darkMutedGray);
      left: 1px solid var(--darkMutedGray);
      radius: 25px 25px 0px 0px;
    }

    .referral-box {
      display: flex;
      align-items: center;
      gap: 5px;

      img {
        cursor: pointer; 
      }
    }

    .buy-moons {
      margin-top: 12px;
      display: flex;
      align-items: baseline;
      gap: 5px;
    }

    .cancel-wrapper {
      position: relative;
    }
    .cancel-line {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 90%;
    }

    .discounted-price {
      position: absolute;
      top: -55%;
      right: -20%;
      font-size: 24px;
      font-weight: 400;
      color: var(--fireRed);
    }

    .subscribe-btn {
      font-size: 16px;
      font-weight: 400;
      height: 44px;
      border-radius: 6px;
      margin-top: 18px;
      text-transform: none;
    }
  }
  .card-bottom {
    height: 311px;
    width: 100%;
    .card-images {
      height: 100%;
      width: 100%;
      border-radius: 0px 0px 25px 25px;
    }
  }
}

html[lang="fa"] .subscription-card-parent-wrapper {
  .card-head {
    p {
      text-align: right;
    }

    .referral-box{
      direction: rtl;
    }

    .buy-moons {
      justify-content: flex-end;
    }
  }
}

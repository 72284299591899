.global-loader-parent {
  height: 100svh;
  width: 100svw;
  box-sizing: border-box;
  position: fixed;
  transition: opacity 0.5s;

  &.visible {
    opacity: 1;
    z-index: 2000;
  }

  &.hide {
    z-index: -2000;
  }

  .loader-transition {
    height: 100%;
    width: 100%;
    transform: scale(0);
    transition: transform 0.5s;
    transition-delay: 0.5s;

    &.scale1 {
      transform: scale(1);
    }
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .loop {
    height: 100px;
    width: 100px;
    border: var(--primary) solid 4px;
    border-radius: 200px;

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(
        to left,
        rgba(187, 95, 39, 0) 0%,
        var(--primary) 30%,
        var(--primary) 70%,
        rgba(187, 95, 39, 0) 100%
      );
      height: 4px;
      left: -100px;
      top: 100px;
      width: 300px;
    }
  }

  .bike-wrapper {
    height: 108px;
    width: 108px;
    animation: drive 3s linear infinite;
  }

  .center-bike {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 26px;
    margin-left: -12px;
  }

  .bike {
    height: 24px;
    width: 25px;
    filter: invert(50%) sepia(50%) saturate(3197%) hue-rotate(168deg)
      brightness(100%) contrast(150%);
  }

  @keyframes drive {
    0% {
      margin-left: -364px;
      opacity: 0;
    }
    33.33% {
      transform: rotate(0deg);
      margin-left: -50px;
      opacity: 1;
    }
    66.66% {
      transform: rotate(-360deg);
      margin-left: -50px;
      opacity: 1;
    }
    100% {
      margin-left: 264px;
      transform: rotate(-360deg);
      opacity: 0;
    }
  }
}

/* font-family for 404 page */
@import url("https://fonts.googleapis.com/css?family=Dosis:300,400,700,800");
/* Righteous */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/font/Righteous/Righteous-Regular.ttf");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/font/Righteous/Righteous-Regular.ttf");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/font/Righteous/Righteous-Regular.ttf");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/font/Righteous/Righteous-Regular.ttf");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/font/Righteous/Righteous-Regular.ttf");
}
/* YekanBakhFaNum */
@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/font/YekanBakhFaNum/YekanBakhFaNum-Bold.woff");
}
@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/font/YekanBakhFaNum/YekanBakhFaNum-Regular.woff");
}
@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/font/YekanBakhFaNum/YekanBakhFaNum-Regular.woff");
}
@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/font/YekanBakhFaNum/YekanBakhFaNum-Regular.woff");
}
@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 200;
  src: url("../../assets/font/YekanBakhFaNum/YekanBakhFaNum-Regular.woff");
}

/* Color palette */
:root {
  --white: #ffffff;
  --semiTransparentWhite: rgba(255, 255, 255, 0.2);
  --gray: #8692a6;
  --darkBlue: #3f94b2;
  --stealBlue: #8692A6;
  --darkStealBlue: #1469B8;
  --skyBlue: #3f94b280;
  --navyBlue: #24344c;
  --deepSlate: #242d3a;
  --midnightNavy: #1f2733;
  --yellow: #ffff00;
  --lemonYellow: #ffd80e;
  --goldenYellow: #ffbb39;
  --goldenRod: #fec33c;
  --primary: #3f94b2;
  --primaryHover: #287f9e;
  --secondaryBlue: rgba(11, 168, 237, 1);
  --teal: #00808b;
  --black: #000;
  --translucentBlack: rgba(0, 0, 0, 0.56);
  --translucentCharcoal: rgba(0, 0, 0, 0.2);
  --semiTransparentBlack: rgba(0, 0, 0, 0.62);
  --halfTransparentBlack: rgba(0, 0, 0, 0.49);
  --transparentBlack: rgba(3, 0, 0, 0.37);
  --mysticMist: #b2a5a5;
  --darkRed: #d23333;
  --pinkishRed: #ffa397;
  --semiTransparentRed: rgba(242, 35, 35, 0.62);
  --Crimson: #ff4d4d;
  --fireRed: #f2330d;
  --vividOrange: #f2330d;
  --indianRed: #cd5c5c;
  --lightGray: #c6c6c6;
  --neutralGray: #dadada;
  --ashGray: #323232;
  --darkGray: #4f4f4f;
  --slateGray: #898989;
  --mutedGray: #a1a0a0;
  --lightSilverGray: #C3C3C1;
  --silverGray: #aaaaaa;
  --charcoalSlate: #292f35;
  --cloudWhite: #dfdfdf;
  --charcoalGray: #2b2929;
  --meadowGreen: #999c20;
  --shadowNavy: #161b22;
  --blueGray: #ebecef;
  --mediumBlueGray: #8f94a3;
  --stormyBlue: #73798c;
  --blackishGray: #1f2228;
  --deepCharcoal: #16181d;
  --midNightGray: #212127;
  --coalBlack: #121317;
  --darkMutedGray: #1f2228;
  --blakishBlue: #0f0e13;
  --skyGray: #e5e7eb;
  --mediumGray: #808080;
  --skyBlueGray: #aaaebb;
  --midNightSlate: rgba(31, 34, 40, 0.5);
  --semiTransparentGray: rgba(193, 193, 193, 0.81);
  --semiTransparentGray2: rgba(63, 58, 58, 0.58);
  --forestGreen: #172624;
  --tealGreen: #46685b;
  --springGreen: #67f17d;
  --darkGreen: #28b06f;
  --semiTransparentDarkGreen: rgba(40, 176, 111, 0.7);
  --magentaPurple: #d611da;
  /* transitions */
  --zoomInOutTransition: transform 0.3s ease-in-out;
}
/* Base styles */
html[lang="en"] * {
  font-family: Roboto, sans-serif;
}
html[lang="fa"] * {
  font-family: YekanBakhFaNum, sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  overflow-x: hidden;
}

a {
  color: var(--darkBlue);
}

*::-webkit-scrollbar {
  width: 0.39em;
}
@media only screen and (max-width: 600px) {
  *::-webkit-scrollbar {
    width: 0.3em;
  }
}
*::-webkit-scrollbar-thumb {
  background-color: var(--slateGray);
  border-radius: 10px;
  height: 10px;
}
/* to prevent input background change by browser ,when autofills */
/* for chrome */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s;
}
/* for firefox and other browser */
input:autofill {
  background: #fff;
}

/* will shift to scss */
.grid-screen-avatar {
  height: 100px;
  width: 100px;
  /* height: 20%;
  max-width: 150px;
  max-height: 150px; */
}

.header-parent-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left {
    display: flex;
    gap: 22px;
    align-items: center;
  }

  .vertical-line {
    width: 1px;
    height: 30px;
    background-color: var(--darkGray);
  }

  .header-right {
    display: flex;
    gap: 42px;
    align-items: center;
  }

  .user-support-box {
    display: flex;
    gap: 22px;
    align-items: center;
  }

  .user-coins-box {
    background-color: var(--ashGray);
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 24px 5px 7px;
    border-radius: 10px;
    position: relative;
  }

  .coin-plus-icon {
    position: absolute;
    right: -13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .header-tabs {
    cursor: pointer;
  }

  @include x-md {
    .user-support-box {
      display: none;
    }
  }

  @include sm {
    .header-left {
      gap: 15px;
    }
  }
}

.ranked-rooms-drop-down {
  .MuiPaper-root {
    min-width: 120px;
    background-color: var(--shadowNavy);
  }
  .MuiList-root {
    padding: 4px 0;
    .drop-down-menu-item {
      height: 25px;
      padding: 5px 9px;
      font-size: 10px;
      font-weight: 400;
      color: var(--white);
      text-align: center;
      align-items: center;
      &:hover {
        background-color: var(--charcoalSlate);
      }
      @include sm {
        min-height: auto;
      }
      .menu-item-text {
        flex-grow: 1;
        font-size: 10px;
        font-weight: 400;
        color: var(--white);
      }
    }
  }
}

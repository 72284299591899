.copied-popup-parent-wrapper {
  min-width: 441px;
  // height: 236px;
  background-color: var(--black);
  position: fixed;
  z-index: 1000;
  border-radius: 15px;
  bottom: 30px;
  transition: transform 500ms;
  left: 9px;
  padding: 25px;
  border: 1px solid var(--darkMutedGray) {
    radius: 25px;
  }

  svg {
    cursor: pointer;
    transition: var(--zoomInOutTransition);

    &:hover {
      transform: scale(1.2);
    }
  }

  &.hide-copied-popup {
    transform: translateY(200%);
  }

  .copied-popup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancel-icon {
      &:hover path {
        fill: var(--Crimson);
      }
    }
  }

  .link-box {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 12px;
    height: 43px;
    background-color: var(--semiTransparentDarkGreen);
    border-radius: 5px;
  }

  @include sm {
    min-width: calc(100% - 12px);
    bottom: 70px;
    left: 6px;
    right: 6px;

    &.hide-invite-popup {
      transform: translateY(130%);
    }
  }
}

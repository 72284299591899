@mixin grid-container-props($height, $templateAreas: null, $gap: 5px) {
  display: grid;
  height: $height;
  grid-auto-flow: dense;
  grid-gap: $gap;

  @include above-sm {
    height: calc(100svh - 150px);
    @if $templateAreas {
      grid-template-areas: $templateAreas;
    }
  }

  @include sm {
    grid-gap: 3px;
  }
}

@mixin grid-item($area) {
  @include above-sm {
    grid-area: $area;
  }
}

@mixin grid-alignment($width, $translateX: 0) {
  @include above-sm {
    max-width: $width;
    transform: translateX($translateX);
  }
}

.grid1 {
  &.grid-container {
    @include grid-container-props(calc(100svh - 100px));
  }
}

.grid2 {
  &.grid-container {
    @include grid-container-props(calc(100svh - 100px), "one two");
    @include above-sm {
      align-items: center;
    }
  }
  &.item1 {
    @include grid-item(one);
    @include above-sm {
      height: calc(100svh - 300px);
    }
  }

  &.item2 {
    @include grid-item(two);
    @include above-sm {
      height: calc(100svh - 300px);
    }
  }
}

.grid3 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two" "three three"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
    .grid-alignment {
      @include grid-alignment(50%, 50%);
    }
  }
}

.grid4 {
  &.grid-container {
    @include grid-container-props(calc(100svh - 100px), "one two" "three four");
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }
}

.grid5 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one one two two three three" "four four four five five five"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
    .grid-alignment {
      @include grid-alignment(33%, 50%);
    }
  }

  &.item5 {
    @include grid-item(five);
    .grid-alignment {
      @include grid-alignment(33%);
    }
  }
}

.grid6 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two three" "four five six"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }
}

.grid7 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two three" "four five six" "seven seven seven"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
    .grid-alignment {
      @include grid-alignment(33%, 100%);
    }
  }
}

.grid8 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one one two two three three" "four four five five six six"
        "seven seven seven eight eight eight"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
    .grid-alignment {
      @include grid-alignment(33%, 50%);
    }
  }

  &.item8 {
    @include grid-item(eight);
    .grid-alignment {
      @include grid-alignment(33%);
    }
  }
}

.grid9 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two three" "four five six" "seven eight nine"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
  }
}

.grid10 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two three four" "five six seven eight" "nine nine ten ten"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
    .grid-alignment {
      @include grid-alignment(24.7%, 100%);
    }
  }

  &.item10 {
    @include grid-item(ten);
    .grid-alignment {
      @include grid-alignment(24.7%);
    }
  }
}

.grid11 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one one one two two two three three three four four four"
        "five five five six six six seven seven seven eight eight eight"
        "nine nine nine nine ten ten ten ten eleven eleven eleven eleven"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
    .grid-alignment {
      @include grid-alignment(24.7%, 47%);
    }
  }

  &.item10 {
    @include grid-item(ten);
    .grid-alignment {
      @include grid-alignment(24.7%, 14%);
    }
  }

  &.item11 {
    @include grid-item(eleven);
    .grid-alignment {
      @include grid-alignment(24.7%, -19%);
    }
  }
}

.grid12 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two three four" "five six seven eight" "nine ten eleven twelve"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
  }

  &.item10 {
    @include grid-item(ten);
  }

  &.item11 {
    @include grid-item(eleven);
  }

  &.item12 {
    @include grid-item(twelve);
  }
}

.grid13 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one one one two two two three three three four four four five five five"
        "six six six seven seven seven eight eight eight nine nine nine ten ten ten"
        "eleven eleven eleven eleven eleven twelve twelve twelve twelve twelve thirteen thirteen thirteen thirteen thirteen"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
  }

  &.item10 {
    @include grid-item(ten);
  }

  &.item11 {
    @include grid-item(eleven);
    .grid-alignment {
      @include grid-alignment(19.7%, 100%);
    }
  }

  &.item12 {
    @include grid-item(twelve);
    .grid-alignment {
      @include grid-alignment(19.7%, 33%);
    }
  }

  &.item13 {
    @include grid-item(thirteen);
    .grid-alignment {
      @include grid-alignment(19.7%, -33%);
    }
  }
}

.grid14 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one one one one two two two two three three three three four four four four five five five five"
        "six six six six seven seven seven seven eight eight eight eight nine nine nine nine ten ten ten ten"
        "eleven eleven eleven eleven eleven twelve twelve twelve twelve twelve thirteen thirteen thirteen thirteen thirteen fourteen fourteen fourteen fourteen fourteen"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
  }

  &.item10 {
    @include grid-item(ten);
  }

  &.item11 {
    @include grid-item(eleven);
    .grid-alignment {
      @include grid-alignment(19.7%, 50%);
    }
  }

  &.item12 {
    @include grid-item(twelve);
    .grid-alignment {
      @include grid-alignment(19.7%, 25%);
    }
  }

  &.item13 {
    @include grid-item(thirteen);
    .grid-alignment {
      @include grid-alignment(19.7%);
    }
  }

  &.item14 {
    @include grid-item(fourteen);
    .grid-alignment {
      @include grid-alignment(19.7%, -25%);
    }
  }
}

.grid15 {
  &.grid-container {
    @include grid-container-props(
      calc(100svh - 100px),
      "one two three four five" "six seven eight nine ten"
        "eleven twelve thirteen fourteen fifteen"
    );
  }

  &.item1 {
    @include grid-item(one);
  }

  &.item2 {
    @include grid-item(two);
  }

  &.item3 {
    @include grid-item(three);
  }

  &.item4 {
    @include grid-item(four);
  }

  &.item5 {
    @include grid-item(five);
  }

  &.item6 {
    @include grid-item(six);
  }

  &.item7 {
    @include grid-item(seven);
  }

  &.item8 {
    @include grid-item(eight);
  }

  &.item9 {
    @include grid-item(nine);
  }

  &.item10 {
    @include grid-item(ten);
  }

  &.item11 {
    @include grid-item(eleven);
  }

  &.item12 {
    @include grid-item(twelve);
  }

  &.item13 {
    @include grid-item(thirteen);
  }

  &.item14 {
    @include grid-item(fourteen);
  }

  &.item15 {
    @include grid-item(fifteen);
  }
}

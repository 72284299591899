.alert-dialog-parent-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1500;

  .MuiPaper-root {
    padding: 45px 20px 20px;
    border-radius: 15px;
    position: relative !important;
  }

  .dialog-icon {
    height: 70px;
    width: 70px;
  }

  .dialog-title {
    margin: 0;
    padding: 8px 10px 0;
    font-size: 25px;
    font-weight: 900;
    line-height: 1.3;
  }

  .dialog-content {
    padding: 5px 10px 0 !important;
  }

  .action-btns-wrapper {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 20px;

    .dialog-btn {
      text-transform: none;
      border-radius: 10px;
      width: 100%;
      white-space: nowrap;
      margin-left: 0;
    }
  }

  @include sm {
    .MuiPaper-root {
      width: 90% !important;
      height: auto !important;
    }

    .action-btns-wrapper {
      flex-wrap: wrap;
    }
  }
}

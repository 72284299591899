// $size is a variable and its value can be chaange below otherise it will take o.8
@mixin middle-icon-size($size: 0.8) {
  transform: translateX(-50%) translateY(-50%) scale($size);
}

.lobby-cards-grid-item {
  height: calc(50% - 2.2222svh - 30px);
  .grid-card-wrapper {
    border-radius: 15px;
    height: 100%;
    width: 100%;
    min-width: 185px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 1.8519svh;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 1;
    &:hover {
      card-background-image {
        background-size: 105% 105%;
      }
    }
  }
  .card-background-image {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 15px;
    transition: background-size 250ms;
    background-size: 100% 100%;
  }

  .black-in-white-back-img {
    filter: grayscale(100%);
  }

  .middle-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include sm {
      @include middle-icon-size($size: 0.6);
    }
    @include above-sm {
      @include middle-icon-size($size: 0.7);
    }
    @include above-md {
      @include middle-icon-size($size: 0.8);
    }
    @include above-xl {
      @include middle-icon-size($size: 1);
    }
  }

  .selected-card-middle-icon {
    filter: invert(42%) sepia(13%) saturate(3197%) hue-rotate(168deg)
      brightness(100%) contrast(80%);
  }

  .selected-card-border {
    border: 2px solid #3f94b2;
  }

  @include x-md {
    height: calc(100% - 2.2222svh - 50px);
  }

  @include sm {
    .grid-card-wrapper {
      padding: 8px;
      min-width: 161px;
    }
    .card-head-icon {
      transform: scale(0.8);
    }
  }
}

html[lang="fa"] .lobby-cards-grid-item {
  .scenarios-title-desc {
    direction: rtl;
  }
}

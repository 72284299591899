.auto-mode-player-parent {
  width: 19.7%;
  padding-bottom: 6.5%;
  position: relative;
  background-color: #2c2f36;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  &.round-top-right {
    border-top-right-radius: 40px;
  }

  &.round-bottom-right {
    border-bottom-right-radius: 40px;
  }

  .player-box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .player-img-wrapper {
    width: 33%;
    height: 100%;
    position: relative;

    &::after {
      content: attr(data-count);
      border-radius: 5px;
      background: var(--darkBlue);
      height: 19px;
      width: 17px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -7px;
      top: 0px;
      z-index: 1;
      color: white;
    }
  }

  .player-img {
    width: auto;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: var(--gray);
  }

  .kicked-typo {
    color: var(--darkRed);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .player-name {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    color: var(--white);
    margin-bottom: 2px;
  }

  .action-icons-box {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);

    display: flex;
    animation: slideInAnimation 0.5s ease forwards;
  }

  @keyframes slideInAnimation {
    0% {
      transform: translate(50%, -20%);
      opacity: 0;
    }
    100% {
      transform: translate(50%, -50%);
      opacity: 1;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.visible {
    visibility: visible;
    animation: fadeInAnimation 0.4s ease forwards;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.info-tab-parent-wrapper {
  margin-top: 3.2407svh;

  .toggle-buttons-group {
    background-color: var(--blackishGray);
    color: var(--blueGray);
    border: 4px solid var(--blackishGray);
    border-radius: 50px;
    overflow: hidden;
  }

  .toggle-button {
    &.MuiToggleButton-root.Mui-selected {
      background-color: var(--skyGray);
      color: var(--deepCharcoal);
    }

    cursor: pointer;
    color: var(--blueGray);
    height: 35px;
    width: 100px;
    //   border-radius: 50px;
    border: none;
    font-size: 11.5px;
    font-weight: 700;

    .toggle-button-icon-Wrap {
      display: flex;
      align-items: center;

      & .light path {
        stroke: #ebecef;
      }
    }
  }

  .coins-required {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
  }

  .tutorial-btn {
    margin-right: 16px;
  }

  .info-btn2 {
    height: 50px;
    width: 140px;
    font-size: 16px;
    border-radius: 10px;
    font-weight: 600;
    white-space: nowrap;
  }

  @include sm {
    margin-top: 8px;

    .toggle-button {
      height: 2px;
      width: 70px;
      font-size: 8px;
      font-weight: 650;
    }

    .info-btn2 {
      height: 45px;
      width: 125px;
      font-size: 14px;

    }
  }
}

// for farsi styling
html[lang="fa"] {
  .info-tab-parent-wrapper {
    direction: rtl;

    .toggle-buttons-wrapper {
      direction: ltr;
      text-align: right;
    }

    .tutorial-btn {
      margin-right: 0;
      margin-left: 16px;
    }

    .info-terms-checkbox {
      margin-left: 0;
      margin-right: -11px;
      padding: 9px 9px 9px 5px;

      @include sm {
        padding: 9px 11px 9px 3px;
      }
    }

    .info-terms-error-msg {
      margin-right: 25px;

      @include sm {
        margin-right: 0px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
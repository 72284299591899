.subscription-parent-wrapper {
  background-color: var(--black);
  overflow: hidden;

  .container {
    height: 100%;
    box-sizing: border-box;
    padding: 2.3148svh 4.3519svh 8.259svh;
    display: flex;
    flex-direction: column;
  }

  .subs-body {
    flex: 1;
    max-width: 90%;
    text-align: center;
    margin: auto {
      top: 48px;
    }
  }

  .toggle-button-wrapper {
    background-color: var(--blackishGray);
    color: var(--blueGray);
    border: 4px solid var(--blackishGray);
    border-radius: 50px;
    overflow: hidden;
  }
  
  .toggle-button {
    &.MuiToggleButton-root.Mui-selected {
      background-color: var(--skyGray);
      color: var(--deepCharcoal);
    }
    cursor: pointer;
    color: var(--blueGray);
    height: 37px;
    width: 154px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 16px;
  }

  @include sm {
    .container {
      padding: 5%;
    }
    .subs-body {
      max-width: 100%;
    }
    .headings {
      padding: 0 19px;
    }
    .toggle-button-wrapper {
      width: 95%;
    }
    .toggle-button {
      width: 95%;
    }
  }

  @include above-xl {
    .cards {
      grid-template-columns: repeat(4, minmax(328.5px, 1fr));
    }
  }
}

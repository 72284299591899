.game-gird-parent-wrapper {
  padding-top: 9px;
  padding-right: 9px;
  flex: 3.5;
  position: relative;
  height: calc(100vh - 15px);
  &.hide {
    display: none;
  }

  .gird-animation {
    transition-property: top, left, right, bottom, width, height, background;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .user-connection-lost-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--black);
    opacity: 0.3;
    z-index: 1100;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .connection-lost-title {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;
    }
  }

  .video, .video-mobile {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    background-color: transparent;
    transform: scaleX(-1);
  }
  
  .video {
    object-fit: cover;
  }
  
  .video-mobile {
    object-fit: contain;
  }

  .avatar-box {
    height: 100%;
    width: 100%;
    background-color: var(--halfTransparentBlack);
    position: absolute;
    border-radius: 15px;
    top: 0;
    left: 0;
    z-index: 1;

    .avatar {
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--gray);
    }

    .night-mode-bg {
      height: 100% !important;
      width: 100% !important;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .user-actions-icons {
    position: absolute;
    top: 12px;
    right: 17px;
    z-index: 1000;
    transform: translate(0, 0);

    display: flex;
    animation: manualSlideInAnimation 0.5s ease forwards;
  }

  @keyframes manualSlideInAnimation {
    0% {
      transform: translateY(20%);
      opacity: 0;
    }
    100% {
      top: 12px;
      opacity: 1;
    }
  }

  .declined-text {
    color: var(--darkRed);
    font-size: 12px;
    position: absolute;
    top: calc(50% + 40px);
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
  }

  .user-status-wrapper {
    z-index: 1000;
    position: absolute;
    left: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-right: 45px;
  }

  .user-name-box {
    background-color: var(--semiTransparentGray2);
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 4px 6px;
    border-radius: 5px;
    align-items: center;
  }

  .god-screen-card-icon {
    z-index: 1000;
    position: absolute;
    right: 11px;
    top: 11px;
    display: flex;
  }

  .sleep-wakeup-btn-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%);
  }

  .sleep-wakeup-btn {
    border-radius: 15px;
    font-size: 11px;
    font-weight: 500;
    width: 79px;
    height: 26px;
    white-space: nowrap;
    text-transform: none;

    &.waiting-btn {
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .MuiCircularProgress-root {
      color: white;
    }
  }

  .sleep-icon {
    position: absolute;
    top: -55%;
    right: -20%;
  }

  .grid-number-box,
  .audio-indicator {
    background-color: var(--secondaryBlue);
    border-radius: 10px;
    height: 30px;
    width: 30px;
    color: var(--white);
    position: absolute;
    bottom: 7px;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  @include sm {
    overflow: hidden;
    height: calc(100vh - 6px);
  }
}

.grid-chat-wrapper {
  max-width: 0;
  overflow: hidden;
  flex: 1;

  &.open-chat {
    flex: 1;
    max-width: 25%;
  }

  @include sm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &.open-chat {
      max-width: 100%;
    }
  }
}

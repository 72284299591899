// PhoneInput.js auth component scss
.phone-input-parent-wrap {
  .verticalLine {
    height: 50%;
    width: 0.25px;
    margin-right: 14px;
    margin-left: 3px;
    background-color: var(--mutedGray);
  }

  .MuiTelInput-Flag {
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
  }

  .phone-input-field {
    color: var(--white);
    width: 100%;
    font-size: 12px;
    background-color: transparent;
    height: 50px;
    border: none !important;
    outline: none;
    font-weight: 500;

    &::placeholder {
      opacity: 1;
      color: var(--mutedGray);
      font-size: 12px;
      font-weight: 500;
    }
  }

  .primary-border-fieldset {
    border-color: var(--primary) !important;
  }

  button {
    padding: 0 !important;
  }
}

// PhoneInput.js auth component scss (select country code)
#select-country.MuiTelInput-Menu {
  .MuiTelInput-ListItemIcon-flag {
    display: none !important;
  }

  .MuiPaper-elevation {
    width: 363px;

    @include sm {
      width: 100vw !important;
      height: 100vh !important;
      max-height: 100vh !important;
      max-width: 100vw !important;
      position: unset !important;
      border-radius: 0;
    }

    @include above-sm {
      width: 0;
      height: 0;
    }

    // &::-webkit-scrollbar {
    //   width: 0.39em;

    //   @include sm {
    //     width: 0.3em;
    //   }
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: var(--slateGray);
    //   border-radius: 10px;
    //   height: 10px;
    // }
  }
}

// for farsi styling, only phone-input
html[lang="fa"] .phone-input-parent-wrap {
  .phone-number-wrapper {
    direction: ltr;
  }

  .phone-input-field {
    padding-right: 14px;
  }

  .phone-input-field::placeholder {
    text-align: right;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
}

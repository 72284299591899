.subscription-plan-parent-wrapper {
  margin: 50px 0;
  padding: 64px;
  background-color: var(--blakishBlue);
  border-radius: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .header-left {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .header-right {
      display: flex;
      gap: 10px;
    }

    .plan-button {
      border-radius: 20px;
      text-transform: none;
      color: var(--white);
      font-size: 14px;
      font-weight: 600;
      height: 30px;
    }
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    grid-gap: 16px;

    .card {
      padding: 24px;
      background-color: var(--coalBlack);
      border: 1px solid var(--blackishGray);
      border-radius: 24px;
      text-align: left;
    }

    .subscription-time {
      display: flex;
      justify-content: space-between;
    }

    .subscription-time-text {
      font-size: 12px;
      font-weight: 400;
      color: var(--blueGray);
      margin-bottom: 5px;
    }

    .card-button {
      font-size: 16px;
      height: 44px;
      border-radius: 6px;
      text-transform: none;
    }

    .list-item {
      padding: 6px 0;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: var(--blueGray);
    }
  }

  @include sm {
    padding: 20px;
  }

  @include md {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }

  @include above-xl {
    .cards-wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.custom-popper {
  z-index: 1450;
  padding-bottom: 10px;

  &.left {
    padding-right: 10px;
  }

  &.top {
    padding-bottom: 10px;
  }

  &.right {
    padding-left: 10px;
  }

  &.bottom {
    padding-top: 10px;
  }

  .dot-line-info-box {
    display: flex;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
  }
  &.top {
    .dot-line-info-box {
      flex-direction: column-reverse;
    }
  }
  &.bottom {
    .dot-line-info-box {
      flex-direction: column;
    }
  }
  &.left {
    .dot-line-info-box {
      flex-direction: row-reverse;
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    background-color: var(--darkBlue);

    border-radius: 50%;
  }

  .line {
    background-color: var(--darkBlue);
  }
  &.top,
  &.bottom {
    .line {
      height: 50px;
      width: 2px;
    }
  }
  &.left,
  &.right {
    .line {
      width: 50px;
      height: 2px;
    }
  }

  .info-box {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--darkBlue);
    padding: 10px;
    background-color: var(--darkBlue);
    color: var(--white);

    width: 259px;
    min-height: 113px;
    border-radius: 10px;
    position: relative;
  }

  .popper-text {
    font-size: 12px;
    margin-right: 30px;
  }

  .popper-btns-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .cancel-icon {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 8px;
    transition: var(--zoomInOutTransition);
    transform: scale(0.85);
    &:hover {
      transform: scale(0.9);
    }

    path {
      fill: var(--white);
    }
  
    &:hover path {
      fill: var(--Crimson);
    }
  }
}

html[lang="fa"] .custom-popper {
  .popper-text {
    text-align: right;
    margin-right: 0px;
    margin-left: 16px;
  }
  .cancel-icon {
    right: 0;
    left: 5px;
  }
  .popper-btns-box {
    direction: rtl;
  }
}

.audio-detector {
    .small-detector {
        background-color: var(--secondaryBlue);
        border-radius: 50%;
        z-index: 1050;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @keyframes quiet {
            25% {
                transform: scaleY(.5);
            }

            50% {
                transform: scaleY(.3);
            }

            75% {
                transform: scaleY(.6);
            }
        }

        @keyframes normal {
            25% {
                transform: scaleY(1);
            }

            50% {
                transform: scaleY(.4);
            }

            75% {
                transform: scaleY(.6);
            }
        }

        @keyframes loud {
            25% {
                transform: scaleY(1);
            }

            50% {
                transform: scaleY(.4);
            }

            75% {
                transform: scaleY(1.1);
            }
        }


        .boxContainer {
            display: flex;
            justify-content: space-between;
            height:18px;
            --boxSize: 2px;
            --gutter: 1px;
            width: calc((var(--boxSize) + var(--gutter)) * 3);
        }

        .box {
            transform: scaleY(.25);
            height: 100%;
            width: var(--boxSize);
            background: var(--white);
            animation-duration: 700ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            border-radius: 12px;
        }

        .stop-animating {
            .box {
                animation: none;

            }
        }

        .box1 {
            animation-name: quiet;
        }

        .box2 {
            animation-name: loud;
        }

        .box3 {
            animation-name: quiet;
        }
    }

}